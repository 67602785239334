import styled from "@emotion/styled";
import { ReleaseModel } from "../../models/Release/model";
import { fontFamily } from "../defaults";

interface ReleaseProps extends ReleaseModel {
  colorize?: boolean;
}

const Release = ({
  releaseName,
  releaseDate,
  segment,
  colorize = false,
}: ReleaseProps) => {
  const TableRow = styled.tr<{ segment?: string }>`
    border-bottom: 1px solid #dcdcdc;

    background-color: ${(props) => getSegmentBackgroundColor(props.segment)};

    &:last-child {
      border-bottom: none;
    }
  `;

  const TableData = styled.td`
    padding: 0.5rem;
    font-family: ${fontFamily};
    font-weight: bold;
    text-align: center;
  `;

  const getSegmentBackgroundColor = (segment?: string) => {
    if (!colorize) {
      return "";
    }
    switch (segment) {
      case "Consumer Cyclicals":
        return "#ffe599";
      case "Equipment":
        return "#c27ba0";
      case "Technology":
        return "#b6d7a8";
      case "Building Products":
        return "#3d85c6";
      case "Healthcare":
        return "#dd7e6b";
      case "Internet":
        return "#6aa84f";
      case "Freight":
        return "#b4a7d6";
      case "Consumer":
        return "#76a5af";
      case "Materials":
        return "#a64d79";
      default:
        return "white";
    }
  };

  return (
    <TableRow key={releaseName} segment={segment}>
      <TableData>{releaseName}</TableData>
      <TableData>
        {new Date(releaseDate).toLocaleDateString("default", {
          month: "long",
          day: "numeric",
        })}
      </TableData>
    </TableRow>
  );
};

export default Release;
