import { ReleaseModel } from "../../models/Release/model";
import Release from "../Release";
import styled from "@emotion/styled";
import { fontFamily } from "../defaults";
import LoadingSpinner from "../LoadingSpinner";

interface ReleaseTableProps {
  releases: ReleaseModel[];
  isLoading?: boolean;
  colorize?: boolean;
}

const Table = styled.table`
  margin: 10px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #dcdcdc;

  &:last-child {
    border-bottom: none;
  }
`;

const TableHeader = styled.th`
  padding: 0.75rem;
  font-weight: bold;
  text-transform: capitalize;
  font-family: ${fontFamily};
`;

const ReleaseTable = ({
  releases,
  isLoading = false,
  colorize = false,
}: ReleaseTableProps) => {
  const releaseRows = releases.map((r, i) => (
    <Release
      releaseName={r.releaseName}
      releaseDate={r.releaseDate}
      segment={r.segment}
      colorize={colorize}
      key={`release-${i}`}
    />
  ));
  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeader>Release Name</TableHeader>
          <TableHeader>Release Date</TableHeader>
        </TableRow>
      </thead>
      {!!isLoading ? (
        <tbody>
          <tr>
            <td colSpan={2} style={{ textAlign: "center" }}>
              <LoadingSpinner />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>{releaseRows}</tbody>
      )}
    </Table>
  );
};

export default ReleaseTable;
