import React from "react";
import ReactDOM from "react-dom/client";
import ReleaseCalendar from "./components/ReleaseCalendar";

const calendar = document.getElementById("cd-release-calendar");
if (!calendar) {
  console.log("No cd-release-calendar target found");
} else {
  const root = ReactDOM.createRoot(calendar);
  console.log("cd-release-calendar target found");
  let url: string | undefined;
  const dataUrl = calendar.getAttribute("data-release-calendar-url");
  if (dataUrl) {
    url = dataUrl;
  }

  root.render(
    <React.StrictMode>
      <ReleaseCalendar
        scheduleURL={url}
        colorize
        onError={(e: Error) => alert(e.message)}
      />
    </React.StrictMode>,
  );
}
