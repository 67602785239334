import { useEffect, useState } from "react";
import { ReleaseModel } from "../../models/Release/model";
import ReleaseTable from "../ReleaseTable";
import { calendarURL } from "../defaults";

interface ReleaseCalendarProps {
  scheduleURL?: string;
  onError?: (error: Error) => void;
  colorize?: boolean;
}

const ReleaseCalendar = ({
  scheduleURL = calendarURL,
  onError,
  colorize = false,
}: ReleaseCalendarProps) => {
  const [releases, setReleases] = useState<ReleaseModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function loadReleases() {
      try {
        const response = await fetch(`${scheduleURL}/releases`);
        const jsonResponse = await response.json();
        setReleases(jsonResponse.releases || []);
        setIsLoading(false);
      } catch (e) {
        console.error("error getting all experts", e);
        throw e;
      }
    }

    loadReleases().catch((e) => {
      if (!!onError) {
        onError(e);
      }
    });
  }, [scheduleURL, onError]);

  return (
    <ReleaseTable
      releases={releases}
      isLoading={isLoading}
      colorize={colorize}
    />
  );
};

export default ReleaseCalendar;
